<template>
  <section>
    <article id="message-container">
      <q-img src="@/assets/images/smartphone.svg" id="rotation-image" />
      <p id="rotate-device-message-text" class="q-mt-md">
        Розверни смартфон вертикально, щоб тобі було зручніше
      </p>
    </article>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LandscapeOrientationWarning",
  props: {
    minWidth: String,
    minHeight: String,
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
@import "@/styles/quasar.scss";

@keyframes deviceRotationAnimation {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotate(90deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(0deg);
  }
}

#message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#rotation-image {
  animation-name: deviceRotationAnimation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  max-height: 80px;
  max-width: 80px;
}

#rotate-device-message-text {
  color: whitesmoke;
  font-size: 14pt;
  font-family: Gilroy-medium;
  font-weight: 600;
  text-align: center;
  width: 100%;
}
</style>
