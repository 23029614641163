import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/telegram.svg'


const _hoisted_1 = { class: "social-icon-section" }
const _hoisted_2 = { href: "https://t.me/BVRbot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createVNode(_component_q_img, {
        src: _imports_0,
        class: "social-icon",
        width: "60px",
        height: "60px",
        alt: "Telegram"
      })
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("span", null, "Telegram", -1))
  ]))
}