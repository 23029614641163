import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/mastercard-logo.svg'
import _imports_1 from '@/assets/images/visa-logo-light.svg'
import _imports_2 from '@/assets/images/prostir-logo-light.svg'
import _imports_3 from '@/assets/images/pci-dss-compliant-logo.svg'
import _imports_4 from '@/assets/images/apple-pay.svg'
import _imports_5 from '@/assets/images/google-pay.svg'
import _imports_6 from '@/assets/images/bvr-pay.svg'


const _hoisted_1 = {
  class: "q-pl-md q-pr-md q-pb-md",
  id: "payment-methods-panel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_q_img, {
      src: _imports_0,
      class: "payment-method-image",
      "no-spinner": "",
      alt: "Mastercard image",
      id: "mastercard-logo",
      style: {"max-width":"35px"}
    }),
    _createVNode(_component_q_img, {
      src: _imports_1,
      class: "payment-method-image",
      "no-spinner": "",
      id: "visa-logo",
      alt: "Visa image",
      height: "18px",
      style: {"max-width":"55px"}
    }),
    _createVNode(_component_q_img, {
      src: _imports_2,
      class: "payment-method-image",
      "no-spinner": "",
      id: "prostir-logo",
      alt: "Prostir image",
      height: "20px",
      style: {"min-width":"104px"}
    }),
    _createVNode(_component_q_img, {
      src: _imports_3,
      class: "payment-method-image",
      "no-spinner": "",
      id: "pci-dss-logo",
      alt: "PCI DSS Compliant image",
      style: {"max-width":"55px"}
    }),
    _createVNode(_component_q_img, {
      src: _imports_4,
      class: "payment-method-image",
      "no-spinner": "",
      id: "apple-pay-logo",
      alt: "Apple Pay logo",
      style: {"max-width":"38px"}
    }),
    _createVNode(_component_q_img, {
      src: _imports_5,
      class: "payment-method-image",
      "no-spinner": "",
      id: "google-pay-logo",
      alt: "Google Pay logo",
      style: {"max-width":"55px"}
    }),
    _createVNode(_component_q_img, {
      src: _imports_6,
      class: "payment-method-image",
      "no-spinner": "",
      id: "bvr-pay-logo",
      alt: "Bvr Pay logo",
      style: {"max-width":"50px"}
    })
  ]))
}