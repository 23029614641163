<template>
    <section class="column items-center">
            <q-spinner-gears size="8em" color="white" style="display: flex" ref="spinnerGears"/>
            <div id="spinner-caption" style="display: flex">{{ message }}</div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: "WaitSpinner",
    props: {
        message: {
            type: String,
            required: true
        }
    }
})
</script>

<style lang="scss" scoped>
#spinner-caption {
    color: white;
    font-family: Gilroy-thin;
    font-weight: 600;
    font-size: 14pt;
    text-align: center;
    margin-top: 20px;
}
</style>