<template>
  <section>
    <span class="support-number">{{ contact }}</span
    ><br />
    <span class="support-number-description">{{ contactDescription }}</span>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContactView",
  props: {
    contact: {
      type: String,
      required: true,
    },
    contactDescription: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.support-number {
  font-size: 11pt;
  font-family: gilroy-medium;
}

.support-number-description {
  font-size: 10pt;
  font-family: gilroy-thin;
}
</style>
