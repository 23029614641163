import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo.svg'


const _hoisted_1 = { id: "header" }
const _hoisted_2 = { id: "brand-section" }
const _hoisted_3 = { id: "operation-name-container" }
const _hoisted_4 = { id: "operation-name-caption" }
const _hoisted_5 = { id: "support-panel-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_technical_support_panel = _resolveComponent("header-technical-support-panel")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("article", null, [
        _createElementVNode("img", {
          src: _imports_0,
          id: "logo"
        })
      ], -1)),
      _cache[1] || (_cache[1] = _createElementVNode("article", { id: "brand-name-container" }, [
        _createElementVNode("div", null, "Банк"),
        _createElementVNode("div", { style: {"margin-top":"-8px"} }, "Власний Рахунок")
      ], -1)),
      _createElementVNode("article", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString($props.operationName), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createVNode(_component_header_technical_support_panel)
    ])
  ]))
}