import i18n from '@/i18n';
import { createPinia } from "pinia";
import Quasar from 'quasar/src/vue-plugin.js';;
import { createApp } from "vue";
import App from "./App.vue";
import quasarUserOptions from "./quasar-user-options";
import router from "./router";

const pinia = createPinia();
const app = createApp(App)

app.use(Quasar, quasarUserOptions)
    .use(pinia)
    .use(i18n)
    .use(router);

app.directive('focus', (el, binding) => {
    if(binding.value) {
        el.focus();
    }
});

app.mount("#app");
