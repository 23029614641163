import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/viber.svg'
import _imports_1 from '@/assets/images/telegram.svg'


const _hoisted_1 = {
  href: "https://bvr.ua/_viber",
  id: "viber-link"
}
const _hoisted_2 = {
  href: "https://t.me/BVRbot",
  id: "telegram-link"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", null, [
      _createElementVNode("a", _hoisted_1, [
        _createVNode(_component_q_img, {
          src: _imports_0,
          height: "50px",
          width: "50px",
          class: "q-ml-md",
          ref: "viber-button-img"
        }, null, 512)
      ]),
      _createElementVNode("a", _hoisted_2, [
        _createVNode(_component_q_img, {
          src: _imports_1,
          height: "50px",
          width: "50px",
          class: "q-ml-md",
          ref: "telegram-button-img"
        }, null, 512)
      ])
    ]),
    _cache[0] || (_cache[0] = _createStaticVNode("<section data-v-18ea0b9e><div class=\"contact\" id=\"support-phone\" data-v-18ea0b9e>044 492 26 00</div><div class=\"contact-description\" id=\"support-phone-description\" data-v-18ea0b9e> За тарифами вашого оператора </div></section><section data-v-18ea0b9e><div class=\"contact\" id=\"support-mobile-phone\" data-v-18ea0b9e>0 800 302 600</div><div class=\"contact-description\" id=\"support-mobile-phone-description\" data-v-18ea0b9e> Безкоштовно по Україні </div></section><section data-v-18ea0b9e><div class=\"contact\" id=\"support-email\" data-v-18ea0b9e>bank@bvr.com.ua</div><div class=\"contact-description\" id=\"support-email-description\" data-v-18ea0b9e> Пиши нам на пошту </div></section>", 3))
  ], 64))
}