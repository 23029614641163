<template>
  <section class="q-pl-md q-pr-md q-pb-md" id="payment-methods-panel">
    <q-img
      src="@/assets/images/mastercard-logo.svg"
      class="payment-method-image"
      no-spinner
      alt="Mastercard image"
      id="mastercard-logo"
      style="max-width: 35px"
    />
    <q-img
      src="@/assets/images/visa-logo-light.svg"
      class="payment-method-image"
      no-spinner
      id="visa-logo"
      alt="Visa image"
      height="18px"
      style="max-width: 55px"
    />
    <q-img
      src="@/assets/images/prostir-logo-light.svg"
      class="payment-method-image"
      no-spinner
      id="prostir-logo"
      alt="Prostir image"
      height="20px"
      style="min-width: 104px;"
    />
    <q-img
      src="@/assets/images/pci-dss-compliant-logo.svg"
      class="payment-method-image"
      no-spinner
      id="pci-dss-logo"
      alt="PCI DSS Compliant image"
      style="max-width: 55px"
    />
    <q-img
      src="@/assets/images/apple-pay.svg"
      class="payment-method-image"
      no-spinner
      id="apple-pay-logo"
      alt="Apple Pay logo"
      style="max-width: 38px"
    />
    <q-img
      src="@/assets/images/google-pay.svg"
      class="payment-method-image"
      no-spinner
      id="google-pay-logo"
      alt="Google Pay logo"
      style="max-width: 55px"
    />
    <q-img
      src="@/assets/images/bvr-pay.svg"
      class="payment-method-image"
      no-spinner
      id="bvr-pay-logo"
      alt="Bvr Pay logo"
      style="max-width: 50px"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaymentMethodsPanel",
});
</script>

<style scoped lang="scss">
#payment-methods-panel {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .payment-method-image {
    margin-right: 15px;
    max-width: 50px;
    margin-top: 15px;
  }

  #payment-methods-panel {
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .payment-method-image {
    margin-right: 20px;
    margin-top: 15px;
    max-width: 70px;
  }

  #payment-methods-panel {
    width: 650px;
  }
}

@media screen and (min-width: 1675px) {
  .payment-method-image {
    max-width: 80px;
    margin-right: 20px;
  }

  #payment-methods-panel {
    width: 850px;
    margin-left: 50px;
  }
}
</style>
