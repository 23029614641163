import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "column items-center" }
const _hoisted_2 = {
  id: "spinner-caption",
  style: {"display":"flex"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_gears = _resolveComponent("q-spinner-gears")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_q_spinner_gears, {
      size: "8em",
      color: "white",
      style: {"display":"flex"},
      ref: "spinnerGears"
    }, null, 512),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1)
  ]))
}