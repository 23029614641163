<template>
  <q-dialog v-model="popupOpen">
    <q-card id="card">
      <q-card-section class="row justify-between">
        <span id="card-header">Є запитаннячка?</span>
        <q-btn
          flat
          round
          icon="close"
          color="black"
          dense
          v-close-popup
          style="margin: -2px -15px 0px 0px"
        />
      </q-card-section>

      <q-card-section id="dialog-main-section">
        <contact-view
          contact="080 030 26 00"
          contact-description="Безкоштовно по Україні"
        />
        <contact-view
          class="q-mt-md"
          contact="044 492 26 00"
          contact-description="Для міжнародних дзвінків"
        />
      </q-card-section>
      <q-card-actions align="center">
        <section
          class="row justify-center items-center justify-center"
          id="social-network-buttons-container"
        >
          <viber-button class="q-mr-xl" />
          <telegram-button />
        </section>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import TelegramButton from "@/common-components/TelegramButton.vue";
import ViberButton from "@/common-components/ViberButton.vue";
import ContactView from "@/components/SupportContactsPopup/child-components/ContactView.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "SupportContactsPopup",
  components: {
    ContactView,
    TelegramButton,
    ViberButton,
  },
  setup() {
    const popupOpen = ref(false);

    const setPopupState = (isOpen: boolean) => {
      popupOpen.value = isOpen;
    };

    return {
      setPopupState,
      popupOpen,
    };
  },
});
</script>

<style lang="scss" scoped>
.social-icon-section {
  text-align: center;
  font-size: 10pt;
  font-family: gilroy-thin;
  font-weight: 600;
}

.social-icon {
  height: 50px;
}

#social-network-buttons-container {
  width: 95%;
  height: 120px;
  background-color: #f8f9f9;
  border-radius: 25px;
}

#card-header {
  font-size: 15pt;
  font-family: gilroy-bold;
}

#card {
  border-radius: 25px;
  width: 90%;
  max-width: 350px;
  height: 70%;
  max-height: 350px;
}

#header-technical-support-panel {
  cursor: pointer;
  color: whitesmoke;
}

#open-popup-icon {
  cursor: pointer;
}

@media screen and (min-width: 300px) {
  #card {
    padding-left: 0px;
    padding-right: 0px;
  }

  #social-network-buttons-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 400px) {
  #card {
    padding-left: 20px;
    padding-right: 20px;
  }

  #social-network-buttons-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (min-width: $minimumDesktopWidth) {
  #header-technical-support-panel {
    display: none;
  }
}

@media screen and (max-width: $minimumDesktopWidth) {
  #header-technical-support-panel {
    display: inline;
  }
}

@media screen and (max-height: 500px) {
  #dialog-main-section {
    margin-top: -40px;
  }
}

@media screen and (min-height: 600px) {
  #dialog-main-section {
    margin-top: -20px;
  }
}

@media screen and (min-height: 800px) {
  #dialog-main-section {
    margin-top: -20px;
  }
}
</style>
