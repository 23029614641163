<template>
  <section>
    <a href="https://bvr.ua/_viber" id="viber-link">
      <q-img
        src="@/assets/images/viber.svg"
        height="50px"
        width="50px"
        class="q-ml-md"
        ref="viber-button-img"
      />
    </a>
    <a href="https://t.me/BVRbot" id="telegram-link">
      <q-img
        src="@/assets/images/telegram.svg"
        height="50px"
        width="50px"
        class="q-ml-md"
        ref="telegram-button-img"
      />
    </a>
  </section>
  <section>
    <div class="contact" id="support-phone">044 492 26 00</div>
    <div class="contact-description" id="support-phone-description">
      За тарифами вашого оператора
    </div>
  </section>
  <section>
    <div class="contact" id="support-mobile-phone">0 800 302 600</div>
    <div class="contact-description" id="support-mobile-phone-description">
      Безкоштовно по Україні
    </div>
  </section>
  <section>
    <div class="contact" id="support-email">bank@bvr.com.ua</div>
    <div class="contact-description" id="support-email-description">
      Пиши нам на пошту
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TechnicalSupportContactsPanel",
});
</script>

<style scoped lang="scss">
section {
  margin-left: 10px;
  color: whitesmoke;
}

.contact {
  margin-left: 30px;
  font-size: 14pt;
  font-family: gilroy-light;
}

.contact-description {
  margin-left: 30px;
  font-size: 10pt;
  font-family: gilroy-light;
}
</style>
