import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_support_block = _resolveComponent("support-block")!
  const _component_support_contacts_popup = _resolveComponent("support-contacts-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_support_block, {
      id: "support-block",
      ref: "supportBlock",
      "header-caption": "Безкоштовно по Україні",
      "header-value": "080 030 26 00",
      onExpand: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handlePopupOpen()))
    }, null, 512),
    _createVNode(_component_support_contacts_popup, { ref: "supportPopupDialog" }, null, 512)
  ], 64))
}