import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "support-number" }
const _hoisted_2 = { class: "support-number-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.contact), 1),
    _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.contactDescription), 1)
  ]))
}