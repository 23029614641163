<template>
  <header id="header">
    <section id="brand-section">
      <article>
        <img src="@/assets/images/logo.svg" id="logo"/>
      </article>
      <article id="brand-name-container" >
        <div>Банк</div>
        <div style="margin-top: -8px;">Власний Рахунок</div>
      </article>
      <article id="operation-name-container">
        <span id="operation-name-caption">{{ operationName }}</span>
      </article>
    </section>
    <section id="support-panel-container">
      <header-technical-support-panel />
    </section>
  </header>
</template>

<script lang="ts">
import HeaderTechnicalSupportPanel from "@/components/HeaderTechnicalSupportPanel/HeaderTechnicalSupportPanel.vue";

export default {
  name: "PageHeader",
  components: {
    HeaderTechnicalSupportPanel
  },
  props: {
    operationName: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/quasar.scss";

$sectionMarginLeft: 10px;

#header {
  width: 100%;
  padding-left: 2vw;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  align-items: center;
}

#brand-section {
  display: flex;
  flex: 1
}

#brand-name-container {
  float: left;
  font-size: 1.3em;
  font-family: gilroy-thin;
  color: whitesmoke;
  margin-left: $sectionMarginLeft;
}

#operation-name-container {
  float: left;
  padding-top: 6px;
  border-left: 1px solid #b3b3b3;
  margin-left: Calc(#{$sectionMarginLeft} + 10px);
  height: Calc(#{$pageHeaderHeight} / 2.5);
}

#operation-name-caption {
  margin-left: Calc(#{$sectionMarginLeft} + 10px);
  font-size: 1.4em;
  font-family: gilroy-medium;
  color: whitesmoke;
}

@media screen and (max-width: 395px) {
  #logo {
    max-width: 35px
  }

  #brand-name-container {
    font-size: 1em;
    margin-left: 5px;
  }

  #support-panel-container {
    margin-right: 5px;
  }
}

@media screen and (min-width: 395px) {
  #brand-name-container {
    font-size: 1.2em;
    margin-left: 5px;
  }

  #support-panel-container {
    margin-right: 5px;
  }
}

@media screen and (min-width: 450px) {
  #logo {
    width: 45px
  }

  #brand-name-container {
    font-size: 1.3em;
    margin-left: 10px;
  }

  #support-panel-container {
    margin-right: 10px;
  }
}

@media screen and (min-width: 900px) {
  #logo {
    width: 45px
  }

  #brand-name-container {
    font-size: 1.3em;
    margin-left: $sectionMarginLeft;
  }
}

@media screen and (max-width: $minimumDesktopWidth) {
  #operation-name-container {
    display: none;
  }
}

@media screen and (min-width: $minimumDesktopWidth) {
  #operation-name-container {
    display: inline;
  }
}
</style>
