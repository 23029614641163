<template>
  <section @click="$emit('expand')" id="support-section" red="supportSection">
    <span id="section-header">{{ headerCaption }}</span
    ><br />
    <span id="section-value">{{ headerValue }}</span>
    <q-icon name="expand_more" class="q-ml-md" id="open-popup-icon" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SupportBlock",
  emits: ["expand"],
  props: {
    headerCaption: {
      type: String,
      required: true,
    },
    headerValue: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
#section-header {
  font-family: gilroy-thin;
}

#section-value {
  font-family: gilroy-medium;
  font-size: 12pt;
}

@media screen and (max-height: 500px) {
  #section-header {
    font-size: 9pt;
  }

  #dialog-main-section {
    margin-top: -40px;
  }

  #section-value {
    font-size: 10pt;
  }
}

@media screen and (min-height: 600px) {
  #section-header {
    font-size: 9pt;
  }

  #section-value {
    font-size: 10pt;
  }

  #dialog-main-section {
    margin-top: -20px;
  }
}

@media screen and (min-height: 800px) {
  #section-header {
    font-size: 11pt;
  }

  #dialog-main-section {
    margin-top: -20px;
  }

  #section-value {
    font-size: 12pt;
  }
}


@media screen and (max-width: 395px) {
  #section-header {
    font-size: 9pt;
  }

  #section-value {
    font-size: 10pt;
  }
}
@media screen and (min-width: 900px) {
  #section-header {
    font-size: 11pt;
  }

  #section-value {
    font-size: 12pt;
  }
}
</style>
