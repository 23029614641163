import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "row justify-center items-center justify-center",
  id: "social-network-buttons-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_contact_view = _resolveComponent("contact-view")!
  const _component_viber_button = _resolveComponent("viber-button")!
  const _component_telegram_button = _resolveComponent("telegram-button")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.popupOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.popupOpen) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { id: "card" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "row justify-between" }, {
            default: _withCtx(() => [
              _cache[1] || (_cache[1] = _createElementVNode("span", { id: "card-header" }, "Є запитаннячка?", -1)),
              _withDirectives(_createVNode(_component_q_btn, {
                flat: "",
                round: "",
                icon: "close",
                color: "black",
                dense: "",
                style: {"margin":"-2px -15px 0px 0px"}
              }, null, 512), [
                [_directive_close_popup]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, { id: "dialog-main-section" }, {
            default: _withCtx(() => [
              _createVNode(_component_contact_view, {
                contact: "080 030 26 00",
                "contact-description": "Безкоштовно по Україні"
              }),
              _createVNode(_component_contact_view, {
                class: "q-mt-md",
                contact: "044 492 26 00",
                "contact-description": "Для міжнародних дзвінків"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "center" }, {
            default: _withCtx(() => [
              _createElementVNode("section", _hoisted_1, [
                _createVNode(_component_viber_button, { class: "q-mr-xl" }),
                _createVNode(_component_telegram_button)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}