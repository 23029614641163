<template>
  <q-layout view="lHh Lpr lFf" class="main-background" ref="mainLayout">
    <section ref="pageContainer" id="page-container">
      <page-header id="header" operationName="Поповнення за посиланням" />
      <article id="content">
        <landscape-orientation-warning class="absolute-center q-mt-md" id="orientation-warning"/>
        <wait-spinner v-show="isLoading" :message="loadingMessage" id="wait-spinner" class="absolute-center"/>
        <div id="router-view-section">
          <router-view id="router-view" v-show="!isLoading"></router-view>
        </div>
      </article>
      <page-footer id="footer"/>
    </section>
  </q-layout>
</template>

<script lang="ts">
import HeaderTechnicalSupportPanel from "@/components/HeaderTechnicalSupportPanel/HeaderTechnicalSupportPanel.vue";
import LandscapeOrientationWarning from "@/components/LandscapeOrientationWarning/LandscapeOrientationWarning.vue";
import PageFooter from "@/components/PageFooter/PageFooter.vue";
import { useP2PCommonStore } from "@/store/P2PCommonStore";
import { computed, ref } from "vue";
import WaitSpinner from "./common-components/WaitSpinner.vue";
import PageHeader from "./components/PageHeader/PageHeader.vue";

export default {
  name: "LayoutDefault",
  components: {
    PageFooter,
    PageHeader,
    LandscapeOrientationWarning,
    HeaderTechnicalSupportPanel,
    WaitSpinner
  },
  setup() {
    const p2PCommonStore = useP2PCommonStore();
    const isLoading = computed(() => p2PCommonStore.getIsAppWaiting);
    const loadingMessage = computed(() => p2PCommonStore.getLoadingMessage);

    return {
      leftDrawerOpen: ref(false),
      isLoading,
      loadingMessage,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/quasar.scss";

#page-container {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}

@supports not (min-height: 100dvh) {
  #page-container { min-height: 100vh; }
}

#router-view-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

#content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

#footer {
  height: $pageFooterHeight;
}

#orientation-warning {
  display: none;
}

@media (max-height: 500px) {
  #header {
    height: Calc(#{$pageHeaderHeight} - 40px);
  }
}

@media (min-height: 501px) {
  #header {
    height: $pageHeaderHeight - 30px;
  }
}

@media (min-height: 700px) {
  #header {
    height: $pageHeaderHeight;
  }
}

@media (max-height: 500px) and (orientation: landscape) and (hover: none) {
  #orientation-warning {
    display: block;
  }

  #footer {
    display: none;
  }

  #wait-spinner {
    display: none;
  }

  #router-view-section {
    display: none;
  }
}

</style>
