<template>
  <support-block
    id="support-block"
    ref="supportBlock"
    header-caption="Безкоштовно по Україні"
    header-value="080 030 26 00"
    @expand="handlePopupOpen()"
  />
  <support-contacts-popup ref="supportPopupDialog" />
</template>

<script lang="ts">
import SupportContactsPopup from "@/components/SupportContactsPopup/SupportContactsPopup.vue";
import { defineComponent, ref } from "vue";
import SupportBlock from "./child-components/SupportBlock.vue";

export default defineComponent({
  name: "HeaderTechnicalSupportPanel",
  components: { SupportBlock, SupportContactsPopup },
  setup() {
    const supportPopupDialog = ref(null as typeof SupportContactsPopup | null);

    const handlePopupOpen = (): void => {
      supportPopupDialog.value?.setPopupState(true);
    };
    return {
      supportPopupDialog,
      handlePopupOpen,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/quasar.scss";

#support-block {
  cursor: pointer;
  color: whitesmoke;
}

@media screen and (max-width: $minimumDesktopWidth) {
  #support-block {
    display: block;
  }
}

@media screen and (min-width: $minimumDesktopWidth) {
  #support-block {
    display: none;
  }
}
</style>
