<template>
  <section class="footer-container row items-center justify-center">
    <div id="support-panel-container">
      <technical-support-contacts-panel
        class="q-mr-xl"
        ref="technicalSupportContactsPanel"
      />
    </div>
    <payment-methods-panel ref="paymentMethodsPanel" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PaymentMethodsPanel from "./child-components/PaymentMethodsPanel.vue";
import TechnicalSupportContactsPanel from "./child-components/TechnicalSupportContactsPanel.vue";

export default defineComponent({
  components: {
    TechnicalSupportContactsPanel,
    PaymentMethodsPanel,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/quasar.scss";

@media screen and (max-width: $minimumDesktopWidth) {
  #support-panel-container {
    display: none;
  }
}

@media screen and (min-width: $minimumDesktopWidth) {
  #support-panel-container {
    display: flex;
  }
}

.footer-container {
  width: 100%;
  border-top: 1px solid whitesmoke;
  position: relative;
}
</style>
