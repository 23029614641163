import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "section-header" }
const _hoisted_2 = { id: "section-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("section", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('expand'))),
    id: "support-section",
    red: "supportSection"
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.headerCaption), 1),
    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.headerValue), 1),
    _createVNode(_component_q_icon, {
      name: "expand_more",
      class: "q-ml-md",
      id: "open-popup-icon"
    })
  ]))
}