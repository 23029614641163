<template>
  <div class="social-icon-section">
    <a href="https://bvr.ua/_viber">
      <q-img
        src="@/assets/images/viber.svg"
        class="social-icon"
        height="60px"
        width="60px"
        alt="Viber"
      />
    </a>
    <br />
    <span>Viber</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ViberButton",
});
</script>

<style lang="scss" scoped>
.social-icon-section {
  text-align: center;
  font-size: 10pt;
  font-family: gilroy-thin;
  font-weight: 600;
}

.social-icon {
  height: 50px;
}
</style>
