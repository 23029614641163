import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "pageContainer",
  id: "page-container"
}
const _hoisted_2 = { id: "content" }
const _hoisted_3 = { id: "router-view-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_landscape_orientation_warning = _resolveComponent("landscape-orientation-warning")!
  const _component_wait_spinner = _resolveComponent("wait-spinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, {
    view: "lHh Lpr lFf",
    class: "main-background",
    ref: "mainLayout"
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createVNode(_component_page_header, {
          id: "header",
          operationName: "Поповнення за посиланням"
        }),
        _createElementVNode("article", _hoisted_2, [
          _createVNode(_component_landscape_orientation_warning, {
            class: "absolute-center q-mt-md",
            id: "orientation-warning"
          }),
          _withDirectives(_createVNode(_component_wait_spinner, {
            message: $setup.loadingMessage,
            id: "wait-spinner",
            class: "absolute-center"
          }, null, 8, ["message"]), [
            [_vShow, $setup.isLoading]
          ]),
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createVNode(_component_router_view, { id: "router-view" }, null, 512), [
              [_vShow, !$setup.isLoading]
            ])
          ])
        ]),
        _createVNode(_component_page_footer, { id: "footer" })
      ], 512)
    ]),
    _: 1
  }, 512))
}