import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer-container row items-center justify-center" }
const _hoisted_2 = { id: "support-panel-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_technical_support_contacts_panel = _resolveComponent("technical-support-contacts-panel")!
  const _component_payment_methods_panel = _resolveComponent("payment-methods-panel")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_technical_support_contacts_panel, {
        class: "q-mr-xl",
        ref: "technicalSupportContactsPanel"
      }, null, 512)
    ]),
    _createVNode(_component_payment_methods_panel, { ref: "paymentMethodsPanel" }, null, 512)
  ]))
}