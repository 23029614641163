export enum ErrorCode {
    Fail = -1,
    Success = 0,
    PaymentCancel = 4013,
    IsPayedOrder = 4014,
    ThreeDsSecurityError = 5001,
    InvalidPhoneNumber = 5004,
    InternalError = 500,
    InvalidCardCredentials = 101,
    CardIssuerError = 105,
    StolenCard = 108,
    NotExistingCard = 111,
    IsufficientFunds = 116,
    LimitExceeded = 130,
    CardIssuerNotResponding = 290,
    TechnicalNetworkError = 291,
    InvalidFromat = 401,
    InvalidAcquirerData = 402,
    ComponentFailure = 403,
    AuthentificationError = 404,
    InvalidSignature = 405,
    TransactionLimitError = 406,
    NotActiveMerchant = 407,
    TransactionNotFound = 408,
    TooManyTransactions = 409,
    PaidOrder = 410,
    OutdatedOrder = 411,
    ConditionErrorOrder = 412,
    UknownCardType = 413,
    CvcRequired = 414,
    SuccessTransactionDayLimit = 420,
    TotalTransactionLimit = 421,
    ForbiddenTransaction = 430,
    Secure3dNotAccepted = 431,
    CardIsInStopList = 432,
    TransactionLimit = 433,
    InvalidCardIssuerCountry = 434,
    IpAddressBlocked = 435,
    AmountTransactionLimit = 436,
    CardAttemptsLimit = 437,
    InvalidCurrencyCode = 438,
    RequestAuthTimespanError = 439,
    AuthTimeLimitError = 440,
    MpiError = 441,
    AcsConnectionError = 442,
    ForbiddenReccurentPayments = 450,
    MpiDisabled = 451,
    ForbiddenP2PTransaction = 452,
    TokenServiceNotActive = 460,
    DeclinedByCustomer = 501,
    SessionExpiredError = 502,
    TransactionDeclinedByMerchant = 503,
    DeclinedByCancellingGateway = 504,
    OperationSequenceError = 505,
    AuthorizationExpiredError = 506,
    PreAuthAlreadyProcessed = 507,
    InvalidAmountPreAuthError = 508,
    OriginalTransactionNotFound = 509,
    ExpiredReversal = 510,
    TransactionSettlementCanceled = 511,
    RepeatedReversal = 512,
    NotCompleted = 601,
    WaitingApprove = 602,
    ProcessingImpossible = 902,
    ProcessingImpossible2 = 909,
    TransactionInProcess = 999,
    RequestCredentialsErrors = 3000,
    ClientNotFound = 3001,
    TransactionCancelError = 3002,
    CardNotFound = 3003,
    CardCredentialsError = 3004,
    InvalidEnergoCurrency = 3005,
    OrderCancellationExpired = 3006,
    OrderCancellationImpossible = 3007,
    CancellationForbidden = 3008,
    ParentDocumentInvalid = 3009,
    RefundInvalidAuthType = 3010,
    WithdrawalAmountError = 3011,
    InsufficientFunds = 3012,
    PaymentNotFound = 3013,
    MaximumCancelAttemptsExceeded = 3014,
    MaximumCancelAmountExceeded = 3015,
    OtherCardRefundNotAllowed = 3016,
    CardOnCardNotFound = 3017,
    OrderCredentionsError = 4000,
    OrderDataReceiveError = 4001,
    OrderPayAttemptsExceeded = 4002,
    OrderPayRequestError = 4003,
    OrderPayExpired = 4004,
    PartnerOrderNotFound = 4005,
    PaymentGatewayUnavailable = 4006,
    MerchantUnavailable = 4007,
    MerchantUrlNotResponding = 4008,
    CardTokenExpired = 4009,
    CardTokenNotFound = 4010,
    CardTokenAlreadyPaid = 4011,
    InvalidRequestSignature = 4012,
    PaymentCancelled = 4013,
    OrderPaid = 4014,
    ClientIdentificationError = 4015,
    CalculateScoreError = 4016,
    MerchantCredentionsError = 4017,
    DBError = 4020,
    InvalidAuthType = 4021,
    MerchantNotActive = 4022,
    PartnerNotActive = 4023,
    InvalidCurrency = 4024,
    MerchantNotFound = 4025,
    BvrClientNotFound = 4026,
    PushSendError = 4027,
    CheckBvrPhoneError = 4028,
    OperationStatusError = 4029,
    OrderDataExpired = 4030,
    PaymentDocumentNotFound = 4031,
    OrderStatusNotFound = 4032,
    SendOtpDataError = 4033,
    SendOtpError = 4034,
    InvalidCustomParams = 4035,
    InvalidRef3 = 4036,
    EmptyVolumeError = 4037,
    DecryptionKeyNotFound = 4038,
    EncryptedPayloadNotFound = 4039,
    MessageTemplateNotFound = 4040,
    SendMessageError = 4041,
    OrderInProcessError = 4042,
    PaymentInfoNotFound = 4043,
    ThreeDSecureInitBrwError = 4044,
    ThreeDSecureAuthEMV3DSv2Error = 4045,
    InvalidPaymentMethod = 4046,
    PaResNotFound = 4047,
    ServerTransactionIdNotFound = 4048,
    CardOnGetCardError = 4049,
    CardOnAddCardError = 4050,
    TodesGetPayTokenError = 4051,
    IncomingPhoneNumberMatchingError = 4052,
    AppleSessionValidationError = 4053,
    ShortLinkHashNotFound = 4069,
    ShortLinkNotActive = 4070,
    InnerApiUserPublicInfoError = 4072,
    BvrRedirectionLinkError = 4118,
    GetDataError = 5000,
    PartnerBlocked = 5001,
    MerchantBlocked = 5002,
    P2PTransactionSumIsInvalid = 5005,
    CreateUserError = 6000,
    EComCreateUserError = 6001,
    LoginError = 6002,
    EComGetUserError = 6003,
    ResetPasswordError = 6004,
    GetUserError = 6005,
    EComUserExists = 6006,
    EcomUpdatePhoneError = 6012,
    ValidateOtpError = 6013,
    ValidatePasswordError = 6014,
    ValidateEmailError = 6015,
    UpdateEmailError = 6016,
    OtpMaxAttemptsExceededError = 6017,
    OtpMinTimeBetweenAttemptsError = 6018,
    OtpExpired = 6019,
    OtpPhoneBlocked = 6020,
    LogoutFailed = 6021
}